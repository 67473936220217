$POPULARITY_PROGRESS_WIDTH: 5.8rem;

.text-ellipsis {
  overflow: hidden; // "overflow" value must be different from "visible"
  text-overflow: ellipsis;
  min-width: 0;
  white-space: nowrap; }

.track {
  display: flex;
  align-items: center;

  margin-bottom: 0.2rem;

  position: relative;
  right: 0.3rem;
  padding: 0 0.3rem;
  width: calc(100% + 0.6rem);

  * {
    user-select: none; }

  &:hover {
    background-color: var(--light-background); }

  &.active {
    border: 1px solid black; }

  &:last-of-type {
    margin-bottom: 1rem; }

  .action {
    cursor: pointer;

    position: relative;
    right: 0.3em;

    flex-basis: 4em;
    margin-right: 0.6em;
    padding-left: 0.4em;

    background-color: var(--gray-background);

    &:hover {
      background-color: var(--font-color);
      color: var(--light-background); } }

  &:not(.active):not(:hover) .action {
    display: none; }

  .venue {
    height: 1.2rem;
    position: absolute;
    right: calc(100% + 0.5rem);
    top: 0; }

  .date {
    flex-basis: 6ch;
    margin-right: 1rem;

    text-align: right;
    color: gray; }

  &:hover .date {
    display: none; }

  .title {
    flex-grow: 2;
    flex-basis: 0; // hack: somehow works because it's a small number
    margin-right: auto;

    @extend .text-ellipsis;

    .untitled {
      color: gray; } }

  .location {
    float: right;
    font-style: italic;
    margin-right: 0.3rem; }

  .podcast {
    padding: 0 0.4em;
    margin-right: 0.3rem;
    background-color: gray;
    color: var(--dark-font-color);

    @media (prefers-color-scheme: dark) {
      background-blend-mode: color; }

    &.live {
      background-color: var(--font-color);
      color: var(--light-background); }

    &.title-date {
      background-color: var(--light-background);
      color: var(--font-color);
      border: 1px solid var(--font-color); } }

  &.active {
    .podcast.title-date {
      border-top-width: 0;
      border-bottom-width: 0; } }

  .user-relation {
    flex-basis: 1.5rem;
    text-align: center;

    .material-icons {
      font-size: 1em;
      position: relative;
      top: 0.2em; } }

  .duration {
    width: 5em;
    text-align: right;
    color: gray; }

  .popularity {
    flex-basis: $POPULARITY_PROGRESS_WIDTH;

    .bar {
      background-color: gray;
      height: 2px; } }

  .progress {
    flex-basis: $POPULARITY_PROGRESS_WIDTH;
    text-align: right; }

  .artist {
    flex-basis: 15rem;
    margin-right: 1rem;

    @extend .text-ellipsis; }

  .origin {
    margin-left: 1rem;

    position: relative;
    bottom: 2px; } }
