@import "fonts";

@import "foundation";

$main-width: 150ch;
$margin: 3rem;
$indent-left: calc(4rem + 6ch);

body {
  --dark-font-color: rgb(34, 34, 34);
  --font-color: var(--dark-font-color);
  --light-background: #eee;
  --gray-background: lightgray; }

@media (prefers-color-scheme: dark) {
  body {
    background-color: black;
    --font-color: white;
    --light-background: #222;
    --gray-background: #444; }

  img {
    filter: invert(100%); } }

#app {
  margin: $margin; }

main {
  margin: 0 auto;
  max-width: $main-width; }

button {
  font-family: $font-family-monospace;
  color: var(--font-color);
  background: none;
  border: 2px solid var(--font-color);
  padding: 0.2rem 1rem;

  cursor: pointer; }

#load-more {
  margin-left: calc(7ch + 1rem); }

#title {
  margin: 2rem 0 3rem;

  h1, h2 {
    font-size: 100%;
    line-height: 1;
    margin-left: $indent-left;
    margin-right: 3rem;

    @media (prefers-color-scheme: dark) {
      color: white; } }

  h1 {
    font-weight: bold; }

  h2 {
    font-style: italic; } }

footer {
  margin-bottom: 1rem;
  margin-left: $indent-left;

  @media (max-width: $main-width + 10ch) {
    margin-left: 1rem;
    margin-right: 1rem; }

  a {
    text-decoration: underline; } }

#legal {
  margin-top: 0.5rem;

  color: gray; }

#login-outer-wrapper {
  display: flex;
  align-items: center;
  height: 40vh; }

#login-inner-wrapper {
  margin: 0 auto; }

@import "track";

#show-pro-tips {
  position: absolute;
  right: 0;
  top: -3rem;
  color: black;
  background-color: lightgray;
  border: none; }

#pro-tips {
  background-color: #eee;
  padding: 1rem 1rem 0.5rem;
  margin-bottom: 1rem;

  .container {
    display: flex;
    padding-top: 1rem; }

  .command-group {
    width: 50%; }

  .command {
    display: flex;
    margin-bottom: 0.5rem; }

  .keys {
    width: 10rem; }

  .key {
    background-color: #222;
    color: white;
    display: inline-block;
    padding: 0.2rem 0.7rem;
    margin-right: 0.5rem;

    text-transform: uppercase;

    svg {
      fill: white;
      height: 1rem;
      width: 1rem;

      position: relative;
      top: 0.2rem; } } }
